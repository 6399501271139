import React, { useEffect, useState } from "react"
import { Input, AutoComplete, Button } from "antd"
import moment from "moment"

import { BrandsI, HotelI } from "../../interface"
import { HotelsOptionsI, OptionsI, RoomsI } from "./interface"
import { STATUS } from "../../enum"
import { brandBategories, roomsCount, dateFormatBooking } from "./initailValue"

import SlantTopBG from "../../assets/images/findahotel/Top-Slant.png"
import SlantBottomBG from "../../assets/images/findahotel/Bottom-Slant-fixed.png"
import RhrLogo from "../../assets/images/RHR-Logo.svg"

import "./FindaHotel.scss"
import ButtonLink from "./WebsiteLink"
import AdvanceSearch from "./AdvanceSearch"
import CheckInOutDetails from "./CheckInOutDetails"

const { Search } = Input

const FindaHotel = ({
  data,
  dropdownClassName,
  placeholder,
  containerId,
  formID,
  bookingFormStatus,
}) => {
  const [rooms, setRooms] = useState<RoomsI[]>(roomsCount)
  const [datePickerHotel, setDatePickerHotel] = useState(null)
  const [isSameDayBooking, setIsSameDayBooking] = useState(false)
  const [roomsHolder, setRoomsHolder] = useState<RoomsI[]>(roomsCount)
  const [datePickerHotelHolder, setDatePickerHotelHolder] = useState(null)
  const [brands, setBrands] = useState<BrandsI[]>([])
  const [getHotels, setGetHotels] = useState<HotelI[]>([])
  const [hotels, setHotels] = useState<HotelsOptionsI[]>([])
  const [hotelsHandle, setHotelsHandle] = useState<HotelsOptionsI[]>([])
  const [noresult, setNoresult] = useState<boolean>(false)
  const [actionButtons, setActionButtons] = useState<boolean>(false)
  const [advanceSearch, setAdvanceSearch] = useState<boolean>(
    formID === "formHotel3" ? true : false
  )
  const [roomShow, setRoomShow] = useState<boolean>(false)
  const [cover, setCover] = useState<boolean>(
    formID === "formHotel1" ? true : false
  )
  const [searchValue, setSearchValue] = useState<string>("")
  const [websiteLink, setWebsiteLink] = useState<string>("")
  const [bookingLink, setBookingLink] = useState<string>("")
  const [gbookingLink, setGBookingLink] = useState<string>("")
  const [bookingSlug, setBookingSlug] = useState<string>("")
  const [bookingForm, setBookingForm] = useState(bookingFormStatus)

  useEffect(() => {
    setBrands(
      data?.tarsiergraphql ? data.tarsiergraphql.getBrands : data?.brands
    )
    setGetHotels(
      data?.tarsiergraphql ? data.tarsiergraphql.getHotels : data?.hotels
    )

    //Booking page data
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search)
      if (params.get("hotel")) {
        const hotel = JSON.parse(JSON.stringify(params.get("hotel")))
        setRooms(JSON.parse(JSON.parse(hotel).rooms))
        setDatePickerHotel(JSON.parse(JSON.parse(hotel).date))
      }
    }

    //remove unique brand category
    const categories = Array.from(
      new Set(
        brands
          ?.filter(
            brand =>
              brand.status === STATUS.PUBLISHED && brand.hotels.length !== 0
          )
          .map(brand => brand.category["name"])
      )
    ).map(category => {
      return brands.find(brand => brand.category["name"] === category)
    })

    //get all available brand category
    categories.map(category => {
      const options: OptionsI[] = []

      //collect all hotel with the same category
      brands
        .filter(brand => brand.status === STATUS.PUBLISHED)
        .filter(brand => brand.category["name"] === category?.category["name"])
        .map(brand => {
          brand.hotels.map((hotel, index) => {
            options.push({
              label: hotel.name.trim(),
              value: hotel.name.trim(),
              address: hotel.address.trim(),
            })
          })
        })

      //autocomplete component data
      hotels.push({
        label: category?.category["name"],
        options: options,
        id: brandBategories.filter(
          brandCat =>
            brandCat.category === category?.category["name"].toUpperCase()
        )[0].id,
      })

      //sort array object based on id
      hotels.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))

      setHotels([...hotels])
      setHotelsHandle([...hotels])
    })
  }, [brands])

  //on change function in form
  const onChange = val => {
    setSearchValue(val)
    setActionButtons(false)

    //return this if "val" is empty
    if (val === "") {
      setNoresult(false)
      return setHotels(hotelsHandle)
    }
    //return this if "val" is equal to hotel name
    if (
      getHotels?.filter(
        hotel =>
          hotel.brand.status === STATUS.PUBLISHED &&
          (hotel.name.toLowerCase().trim() === val.toLowerCase().trim() ||
            hotel.address.toLowerCase().trim() === val.toLowerCase().trim())
      ).length !== 0
    ) {
      return onSearch(val)
    }

    const searchHotel: HotelsOptionsI[] = []

    //filter all hotel if option value includes "val"
    hotelsHandle.map(hotel => {
      const opt = hotel.options.filter(
        option =>
          option.value.toLowerCase().includes(val.toLowerCase().trim()) ||
          option.address.toLowerCase().includes(val.toLowerCase().trim())
      )
      if (opt.length !== 0) {
        searchHotel.push({
          label: hotel.label,
          options: opt,
          id: 1,
        })
      }
    })

    if (typeof window !== "undefined") {
      const ele = document.body
      ele?.classList.remove("autoCompleteHidden")

      if (searchHotel.length === 0) {
        ele?.classList.add("autoCompleteHidden")
        ele?.classList.remove("autoCompleteShow")
      }
    }

    setHotels([...searchHotel])
  }

  //search a hotel
  const onSearch = val => {
    setHotels([])
    setRooms([
      {
        label: "Room",
        adult: 1,
        children: 0,
      },
    ])
    setRoomShow(false)

    const searchHotel: HotelsOptionsI[] = []

    //filter all hotel if option value includes "val"
    hotelsHandle.map(hotel => {
      const opt = hotel.options.filter(option =>
        option.value.toLowerCase().includes(val.toLowerCase().trim())
      )
      if (opt.length !== 0) {
        searchHotel.push({
          label: hotel.label,
          options: opt,
          id: 1,
        })
      }
    })

    setHotels([...searchHotel])

    //filtering all published hotel
    const hotel = getHotels?.filter(
      hotel =>
        hotel.brand.status === STATUS.PUBLISHED &&
        hotel.name.toLowerCase().trim() === val.toLowerCase().trim()
    )

    if (typeof window !== "undefined") {
      const ele = document.body

      //return this if hotel has a "partner_hotel_code"
      if (hotel[0]?.partner_hotel_code) {
        ele?.classList.remove("autoCompleteShow")
        setAdvanceSearch(true) // Comment out to temporarily hide advance search
        setBookingLink(hotel[0].booking_engine_link) // Temporary show booking link
        setWebsiteLink(hotel[0].website_link) // Temporary show website link
        setNoresult(false)
        setGBookingLink(hotel[0].group_booking_link)
        setBookingSlug(hotel[0].slug)
        return setActionButtons(true) //Temporary set to true
      }

      //return this if hotel length is 0
      if (hotel.length === 0) {
        ele?.classList.remove("autoCompleteShow")
        if (hotels.length > 0) {
          ele?.classList.add("autoCompleteShow")
        }

        setAdvanceSearch(false)
        setActionButtons(false)
        return setNoresult(true)
      }

      ele?.classList.remove("autoCompleteShow")
      setActionButtons(true)
      setAdvanceSearch(false)
      setWebsiteLink(hotel[0].website_link)
      setBookingLink(hotel[0].booking_engine_link)
      return setNoresult(false)
    }
  }

  //add and remove class "active" to element based on element id
  const findHotel = (bool: boolean) => {
    if (typeof window !== "undefined") {
      const ele = document.getElementById(formID)

      if (ele)
        if (ele?.classList.contains("active") && !bool) {
          setActionButtons(false)
          setNoresult(false)
          setAdvanceSearch(false)
          setRooms([
            {
              label: "Room",
              adult: 1,
              children: 0,
            },
          ])

          if (formID === "formHotel1") {
            setCover(true)
          }

          return ele?.classList.remove("active")
        }
      return ele?.classList.add("active")
    }
  }

  //remove active class when close button click
  const closeHotelFullscreen = () => {
    setSearchValue("")
    setActionButtons(false)
    setAdvanceSearch(false)
    setRooms([
      {
        label: "Room",
        adult: 1,
        children: 0,
      },
    ])
    setHotels([...hotelsHandle])
    setNoresult(false)
    if (typeof window !== "undefined") {
      const ele = document.getElementById("findhotelFullscreen")
      const eleClose = document.body
      eleClose?.classList.remove("autoCompleteShow")
      eleClose?.classList.remove("autoCompleteHidden")

      if (ele?.classList.contains("active"))
        document.body.style.overflow = "auto"
      return ele?.classList.remove("active")
    }
  }

  //add a room
  const addRoom = () => {
    if (typeof window !== "undefined") {
      const ele = document.getElementById("roomList")
      if (rooms.length >= 1) {
        ele?.classList.add("auto-scroll")
      }
    }

    if (rooms.length < 9) {
      return setRooms([
        ...rooms,
        {
          label: `Room`,
          adult: 1,
          children: 0,
        },
      ])
    }
  }

  //remove a room
  const removeRoom = index => {
    if (typeof window !== "undefined") {
      const ele = document.getElementById("roomList")
      if (rooms.length === 2) {
        ele?.classList.remove("auto-scroll")
      }
    }

    if (rooms.length > 1) {
      return setRooms(
        rooms.filter((room, key) => {
          return key !== index
        })
      )
    }
  }

  //add Adult
  const addAndMinus = (index, count, operation, person) => {
    rooms.map((room, key) => {
      if (
        operation === "add" &&
        key === index &&
        person === "adult" &&
        count < 4
      )
        return (room.adult = count + 1)

      if (
        operation === "add" &&
        key === index &&
        person === "child" &&
        count < 2
      )
        return (room.children = count + 1)

      if (
        operation === "minus" &&
        key === index &&
        person === "adult" &&
        count > 1
      )
        return (room.adult = count - 1)

      if (
        operation === "minus" &&
        key === index &&
        person === "child" &&
        count > 0
      )
        return (room.children = count - 1)
    })

    return setRooms([...rooms])
  }

  //add all Adults
  const addAllPersons = (rooms, person) => {
    return rooms.reduce(
      (accumulator, current) => accumulator + current[person],
      0
    )
  }

  const blockDate = (date, selectedDate) => {
    const today = moment()
    const yesterday = moment(today).subtract(1, "days")
    const checkInDate = selectedDate?.[0]
    const checkOutDate = selectedDate?.[1]
    const isBeforeToday = moment(date).isBefore(today, "day")
    const isOnCheckInDate = moment(date).isSame(checkInDate)
    const isOnCheckOutDate = moment(date).isSame(checkOutDate)

    let isDateDisabled = false

    if (!selectedDate) {
      isDateDisabled = yesterday > date
    } else if (checkInDate) {
      isDateDisabled = isBeforeToday || isOnCheckInDate
    } else if (checkOutDate) {
      isDateDisabled = isBeforeToday || isOnCheckOutDate
    }

    return isDateDisabled
  }

  const hotelRoom = JSON.stringify({
    rooms: JSON.stringify(rooms),
    date: JSON.stringify(datePickerHotel),
  })

  //save Booking
  const saveBooking = () => {
    if (typeof window !== "undefined") {
      window.onbeforeunload = null
      const newDate: string[] = []
      const newRoom: RoomsI[] = []
      document.querySelectorAll(".ant-picker-range input").forEach(el => {
        newDate.push((el as HTMLInputElement).value)
      })
      document.querySelectorAll(".rooms-container").forEach(el => {
        newRoom.push({
          label: "Room",
          adult: Number(
            el.children[1].getElementsByClassName("children-adult--count")?.[0]
              .textContent
          ),
          children: Number(
            el.children[1].getElementsByClassName("children-adult--count")?.[1]
              .textContent
          ),
        })
      })
      const hotelRoom = JSON.stringify({
        rooms: JSON.stringify(newRoom),
        date: JSON.stringify(newDate),
      })

      return window.location.replace(
        window.location.pathname + `?hotel=${hotelRoom}`
      )
    }
  }

  //edit Booking
  const editBooking = () => {
    setDatePickerHotelHolder(datePickerHotel)
    setRoomsHolder(rooms)
    setBookingForm(true)
  }

  //cancel Booking
  const cancelBooking = () => {
    //Booking page data
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search)
      if (params.get("hotel")) {
        const hotel = JSON.parse(JSON.stringify(params.get("hotel")))
        setRooms(JSON.parse(JSON.parse(hotel).rooms))
        setDatePickerHotel(JSON.parse(JSON.parse(hotel).date))
      }
    }
    setBookingForm(false)
  }

  //handle date
  const dataPicker = val => {
    setDatePickerHotel(val)
  }

  //toggle rooms
  const showRooms = (outsideclicked: Boolean = false) => {
    if (outsideclicked) {
      return setRoomShow(false)
    }
    return setRoomShow(!roomShow)
  }

  //close form
  const closeForm = () => {
    findHotel(false)
    setSearchValue("")
    if (typeof window !== "undefined") {
      const ele = document.body
      ele?.classList.remove("autoCompleteShow")
      ele?.classList.remove("autoCompleteHidden")
    }
  }

  //open form
  const openForm = () => {
    findHotel(true)
    setHotels(hotelsHandle)
  }

  return (
    <div id={containerId}>
      {formID === "formHotel2" && (
        <>
          <img src={RhrLogo} className="rhr-logohotel" alt="RHR Logo" />
          <p onClick={closeHotelFullscreen} className="close-hotel-btn">
            <span id="cross"></span> Close
          </p>
          <img src={SlantTopBG} className="slantTop" alt="Slant Top Bg" />
          <img
            src={SlantBottomBG}
            className="slantBottom"
            alt="Slant Bottom Bg"
          />
        </>
      )}

      {bookingForm ? (
        <>
          <div className="form-hotel" id={formID}>
            {formID !== "formHotel3" && <h3>FIND THE BEST HOTEL FOR YOU!</h3>}
            {formID === "formHotel1" && (
              <span onClick={closeForm} className="close">
                Close
              </span>
            )}
            {formID !== "formHotel3" && (
              <AutoComplete
                dropdownClassName={dropdownClassName}
                dropdownMatchSelectWidth={200}
                options={hotels}
                onChange={e => onChange(e)}
                value={searchValue}
              >
                <Search
                  placeholder={placeholder}
                  allowClear
                  enterButton={true}
                  size="large"
                  className="find-hotel"
                  onSearch={e => onSearch(e)}
                  onClick={openForm}
                />
              </AutoComplete>
            )}

            {formID !== "formHotel3" && (
              <span
                style={cover ? {} : { display: "none" }}
                //onClick={e => findHotel(true)}
                onClick={openForm}
                className="cover"
              ></span>
            )}
            {formID !== "formHotel3" && (
              <span
                style={
                  noresult
                    ? { opacity: 1 }
                    : { opacity: 0, pointerEvents: "none" }
                }
                className="no-result-found-findahotel"
              >
                No result found
              </span>
            )}
            {advanceSearch && (
              <AdvanceSearch
                datePickerHotel={datePickerHotel}
                formID={formID}
                dataPicker={dataPicker}
                blockDate={blockDate}
                rooms={rooms}
                addAllPersons={addAllPersons}
                showRooms={showRooms}
                roomShow={roomShow}
                addAndMinus={addAndMinus}
                removeRoom={removeRoom}
                addRoom={addRoom}
                gbookingLink={gbookingLink}
                isSameDayBooking={isSameDayBooking}
                setIsSameDayBooking={setIsSameDayBooking}
              />
            )}
            {bookingLink && (
              <ButtonLink
                link={bookingLink}
                action={actionButtons}
                className="rhr-button"
                title="BOOK NOW"
              />
            )}
            {websiteLink && (
              <ButtonLink
                link={websiteLink}
                action={actionButtons}
                className="rhr-button white"
                title="KNOW MORE"
              />
            )}
          </div>
          {formID === "formHotel3" && (
            <div className="action-button-booking">
              <Button
                className="edit-btn"
                type="default"
                onClick={cancelBooking}
              >
                Cancel
              </Button>
              <Button
                className="primary-btn"
                type="primary"
                onClick={saveBooking}
              >
                Save
              </Button>
            </div>
          )}
        </>
      ) : (
        <CheckInOutDetails
          dateFormatBooking={dateFormatBooking}
          datePickerHotel={datePickerHotel}
          rooms={rooms}
          addAllPersons={addAllPersons}
          editBooking={editBooking}
        />
      )}
    </div>
  )
}

export default FindaHotel
